import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import api from '../../utils/http';
import TreeCompo from '../../components/tree';
import { Flex, message, Tag, Typography } from 'antd';
import { setCoupons, setShopOpen } from '../../redux/Reducer';
import { DragOutlined, EditOutlined } from '@ant-design/icons';
import { getRandomColor } from '../../utils/getRandomColor';
// import { isMobile, isDesktop } from 'react-device-detect';
import { socketSingleton } from '../../utils/useSockets';

const TreeHOC = () => {
	const dispatch = useDispatch();
	const { user, coupons } = useSelector(state => state);
	const { get, patch } = api();

	const [open, setOpen] = useState(false);
	const [selectedID, setSelectedID] = useState(null);
	const [depth, setDepth] = useState(null);
	const [addData, setAddData] = useState(null);

	const [treeData, setTreeData] = useState([]);
	const [shopData, setShopData] = useState({
		address: {},
		name: '',
		phone: '',
		schedule: [
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
		],
	});

	// Filters for pagination
	// const [itemCatFilters, setItemCatFilters] = useState({
	// 	pageNumber: 1,
	// 	pageSize: 30,
	// });
	// const [itemFilters, setItemFilters] = useState({
	// 	pageNumber: 1,
	// 	pageSize: 30,
	// });

	const [openedMobileReorderNode, setOpenedMobileReorderNode] = useState(null);

	useEffect(() => {
		get('')
			.then(response => {
				setShopData({
					...response.data,
					schedule: response.data.schedule.map(item =>
						item.length === 0 ? ['0000', '0000'] : item,
					),
					scheduleOpen: response.data.schedule.map(item => item.length !== 0),
					areas: response.data.areas.map(item => ({
						...item,
						fillColor: getRandomColor(),
					})),
				});
				// console.log('haaaa', response?.data?.status);
			})
			.catch(err => {
				message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
			});

		get('/coupons', {
			pageNumber: 1,
			pageSize: 30,
		})
			.then(response => {
				response && dispatch(setCoupons(response.data.data));
				// setTotalPages(response.data.totalPages);
			})
			.catch(err => {
				message.error(err.response?.data?.message);
			});
	}, []);

	useEffect(() => {
		if (open) {
			return;
		}
		setSelectedID(null);
		setAddData(null);
		setDepth(null);
	}, [open]);

	// Fetch data for item categories
	// useEffect(() => {
	// 	if (open) {
	// 		return;
	// 	}
	// 	get('/item-categories', itemCatFilters)
	// 		.then(response => {
	// 			dispatch(setItemCategories(response.data));
	// 		})
	// 		.catch(error => message.error(error.response?.data?.message));
	// }, [itemCatFilters, open]);

	// // Fetch data for items
	// useEffect(() => {
	// 	if (open) {
	// 		return;
	// 	}
	// 	get('/items', itemFilters)
	// 		.then(response => {
	// 			dispatch(setItems(response.data));
	// 		})
	// 		.catch(error => message.error(error.response?.data?.message));
	// }, [itemFilters, open]);

	useEffect(() => {
		getTreeData();
	}, []);

	const getTreeData = async () => {
		try {
			const res = await get('/tree');
			// console.log(res.data);
			if (!res?.data) {
				return;
			}

			const td = res.data.map(it => ({
				...it,
				items: it.items.map(itt => ({
					...itt,
					price: itt.price / 100,
					modifiers: itt.modifiers.map(m => ({
						...m,
						options: m.options.map(o => ({
							...o,
							price: o.price / 100,
						})),
					})),
				})),
			}));

			const newTreeData = buildTreeData(
				td,
				// itemCategories.data,
				// items.data.map(it => ({ ...it, price: it.price / 100 })),
			);

			setTreeData(newTreeData);
		} catch (err) {
			console.log('get tree err ', err);
		}
	};

	// useEffect(() => {
	// 	return;
	// 	if (itemCategories.data?.length > 0 && items.data?.length > 0) {
	// 		console.log('deee categories', itemCategories.data);
	// 		console.log('deee items', items.data);

	// 		const newTreeData = buildTreeData(
	// 			itemCategories.data,
	// 			items.data.map(it => ({ ...it, price: it.price / 100 })),
	// 		);

	// 		setTreeData(newTreeData);
	// 	}
	// }, [itemCategories, items]);

	const onInfoUpdate = () => {
		// den stelno user, cuisines, files, comment
		patch('', {
			...shopData,
			user: undefined,
			cuisines: undefined,
			files: undefined,
			// user: {
			// 	...shopData.user,
			// 	language: user.language,
			// },
			schedule: shopData.schedule.map((day, index) => {
				// if (day[0] === '0000' && day[1] === '0000') {
				// 	return [];
				// }
				if (!shopData.scheduleOpen?.[index]) {
					return [];
				}
				return day.map(time => time.replace(':', ''));
			}),
			scheduleOpen: undefined,
			areas: !shopData.areas
				? shopData.areas
				: shopData.areas.map(item => {
						const pmo = Number(`${item.minimumOrder}`?.replaceAll(',', '.'));
						if (!isFinite(pmo)) {
							// message.error('Η τιμή πρέπει να είναι νούμερο');
							throw new Error('Η τιμή πρέπει να είναι νούμερο');
						}
						const pdc = Number(`${item.deliveryCost}`?.replaceAll(',', '.'));
						if (!isFinite(pdc)) {
							// message.error('Η τιμή πρέπει να είναι νούμερο');
							throw new Error('Η τιμή πρέπει να είναι νούμερο');
						}
						return {
							...item,
							minimumOrder: Math.round(pmo * 100),
							deliveryCost: Math.round(pdc * 100),
						};
					}),
		})
			.then(() => {
				message.success('Shop updated successfully');
			})
			.catch(err => {
				message.error(err.response?.data?.message);
			});
	};

	const onActiveChange = id => {
		patch(`/coupons/status?id=${id}`)
			.then(response => {
				message.success('Success!');
				dispatch(
					setCoupons(
						coupons.map(coupon =>
							coupon.id === id ? { ...coupon, active: !coupon.active } : coupon,
						),
					),
				);
			})
			.catch(err => {
				message.error(err.response?.data?.message);
			});
	};

	// Infinite scroll logic for categories, items, modifiers, and modifier options
	// const handleInfiniteScroll = type => {
	// 	if (type === 'itemCategories') {
	// 		setItemCatFilters(prev => ({
	// 			...prev,
	// 			pageNumber: prev.pageNumber + 1,
	// 		}));
	// 	} else if (type === 'items') {
	// 		setItemFilters(prev => ({
	// 			...prev,
	// 			pageNumber: prev.pageNumber + 1,
	// 		}));
	// 	}
	// };

	// Helper function to build the hierarchical structure for tree
	// const buildTreeData = (categories, items) => {
	const buildTreeData = categories => {
		return categories.map(category => ({
			title: (
				<Flex justify='space-between'>
					<Tag style={{ minWidth: 50, maxWidth: 550, width: '100%' }} color='blue'>
						{category.id}
						{category.name}
					</Tag>
					<DragOutlined
						onClick={e => {
							setOpenedMobileReorderNode({
								type: 'category',
								item: category,
							});
						}}
					/>
				</Flex>
			),
			label: category.name,
			key: `category-${category.id}`,
			id: category.id,
			children: category.items
				// ?.filter(item => item.category === category.id)
				.map(item => ({
					title: (
						<Flex justify='space-between'>
							<Tag
								style={{ minWidth: 150, maxWidth: 550, width: '100%' }}
								color={item.available ? 'green' : 'lightgrey'}
							>
								<Flex justify='space-between'>
									<span>{item.id}</span>
									<span>{item.name}</span>
									<span>{item.price}€</span>
								</Flex>
							</Tag>
							<EditOutlined
								onClick={() => {
									setDepth(2);
									setOpen(true);
									setSelectedID(item.id);
									setAddData(item);
								}}
							/>
							<DragOutlined
								onClick={() => {
									setOpenedMobileReorderNode({
										type: 'item',
										item,
									});
								}}
							/>
						</Flex>
					),
					key: `item-${category.id}-${item.id}`,
					children: item.modifiers.map(modifier => ({
						title: (
							<Flex justify='space-between'>
								<Tag
									style={{ minWidth: 50, maxWidth: 550, width: '100%' }}
									color='red'
								>
									{modifier.name}
								</Tag>
								<EditOutlined
									onClick={() => {
										setDepth(3);
										setOpen(true);
										setSelectedID(modifier.id);
										setAddData(modifier);
									}}
								/>
								<DragOutlined
									onClick={() => {
										setOpenedMobileReorderNode({
											type: 'modifier',
											item: modifier,
										});
									}}
								/>
							</Flex>
						),
						key: `modifier-${category.id}-${item.id}-${modifier.id}`,
						children: modifier.options.map(modifierOption => ({
							title: (
								<Flex justify='space-between'>
									<Tag
										style={{ minWidth: 200, maxWidth: 550, width: '100%' }}
										color={modifierOption.available ? 'purple' : 'lightgrey'}
									>
										<Flex justify='space-between'>
											<span>{modifierOption.id}</span>
											<span>{modifierOption.name}</span>
											<span>{modifierOption.price}€</span>
										</Flex>
									</Tag>
									<EditOutlined
										onClick={() => {
											setDepth(4);
											setOpen(true);
											setSelectedID(modifierOption.id);
											setAddData(modifierOption);
										}}
									/>
								</Flex>
							),
							key: `modifierOption-${category.id}-${item.id}-${modifier.id}-${modifierOption.id}`,
						})),
					})),
				})),
		}));
	};

	// const onChangeMobileReorderNode = async () => {};

	// console.log('add ', addData);
	// console.log('shop ', shopData);
	// console.log('tree ', treeData);
	return (
		<TreeCompo
			setShopData={setShopData}
			shopData={shopData}
			data={treeData}
			setData={setTreeData}
			user={user}
			onFinish={onInfoUpdate}
			// onScroll={handleInfiniteScroll} // Pass infinite scroll handler
			open={open}
			setOpen={setOpen}
			selectedID={selectedID}
			setSelectedID={setSelectedID}
			depth={depth}
			setDepth={setDepth}
			addData={addData}
			setAddData={setAddData}
			coupons={coupons}
			onActiveChange={onActiveChange}
			getTreeData={getTreeData}
			openedMobileReorderNode={openedMobileReorderNode}
			closeMobileReorderNode={() => {
				setOpenedMobileReorderNode(null);
			}}
			openCategoriesMobileReorder={() => {
				setOpenedMobileReorderNode({
					type: 'categories',
					item: treeData,
				});
			}}
		/>
	);
};

export default TreeHOC;
