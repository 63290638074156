if (import.meta.env.DEV) await import('./scan');
import { Provider } from 'react-redux';
import Router from './router';
import { BrowserRouter } from 'react-router-dom';
import { persistor, store } from './redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
import 'react-phone-number-input/style.css';

const App = () => {
	return (
		<BrowserRouter>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<Router />
				</PersistGate>
			</Provider>
		</BrowserRouter>
	);
};

export default App;
