import React, { useEffect, useState } from 'react';
import api from '../../../utils/http';
import ShopForm from '../../../components/store/shop';
import { useSelector } from 'react-redux';
import { message } from 'antd';
import { getRandomColor } from '../../../utils/getRandomColor';

const Shop = () => {
	const { user, currentTab } = useSelector(state => state);

	const { get } = api();

	const [shopData, setShopData] = useState({
		address: {},
		user: {},
		name: '',
		phone: '',
		schedule: [
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
			['08:00', '22:00'],
		],
		files: [],
		cuisines: [],
	});

	useEffect(() => {
		if (currentTab !== '1' || !user.shop) {
			return;
		}
		get('')
			.then(response => {
				setShopData({
					...response.data,
					schedule: response.data.schedule.map(item =>
						item.length === 0 ? ['0000', '0000'] : item,
					),
					scheduleOpen: response.data.schedule.map(item => item.length !== 0),
					areas: response.data.areas.map(item => ({
						...item,
						minimumOrder: !item.minimumOrder
							? item.minimumOrder
							: item.minimumOrder / 100,
						deliveryCost: !item.deliveryCost
							? item.deliveryCost
							: item.deliveryCost / 100,

						fillColor: getRandomColor(),
					})),
				});
				console.log('sssss', shopData);
			})
			.catch(err => {
				message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
			});
	}, [currentTab, user.shop]);

	return <ShopForm shopData={shopData} setShopData={setShopData} user={user} />;
};

export default Shop;
