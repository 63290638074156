export const translations = {
	email: 'Email',
	password: 'Κωδικός',
	submit: 'Υποβολή',
	cancel: 'Ακύρωση',
	hello: 'Γειά σου, ',
	home: 'Αρχική',
	users: 'Χρήστες',
	shops: 'Καταστήματα',
	cuisines: 'Κουζίνες',
	languages: 'Γλώσσες',
	cities: 'Πόλεις',
	changeShop: 'Αλλαγή Καταστήματος',
	nameSearch: 'Αναζήτηση ονόματος',
	apply: 'Εφαρμογή',
	reset: 'Επαναφορά',
	selected: 'Επιλεγμένα',
	items: 'αντικείμενα ',
	delete: 'Διαγραφή',
	addUser: 'Προσθήκη Χρήστη',
	editUser: 'Επεξεργασία Χρήστη',
	addShop: 'Προσθήκη Καταστήματος',
	addCuisine: 'Προσθήκη Κουζίνας',
	editCuisine: 'Επεξεργασία Κουζίνας',
	addLanguage: 'Προσθήκη Γλώσσας',
	addCity: 'Προσθήκη Πόλης',
	editCity: 'Επεξεργασία Πόλης',
	addNew: 'Νέα Προσθήκη',
	ID: 'ID',
	name: 'Όνομα',
	page: 'Σελίδα',
	firstName: 'Όνομα',
	lastName: 'Επώνυμο',
	phone: 'Τηλέφωνο',
	code: 'Κωδικός',
	activateLightMode: 'Ενεργοποίηση Φωτεινού Τρόπου',
	activateDarkMode: 'Ενεργοποίηση Σκοτεινού Τρόπου',
	logout: 'Αποσύνδεση',
	login: 'Σύνδεση',
	saveShop: 'Αποθήκευση Καταστήματος',
	schedule: 'Πρόγραμμα',
	address: 'Διεύθυνση',
	street: 'Οδός',
	streetNumber: 'Αριθμός',
	postalCode: 'Ταχυδρομικός Κώδικας',
	area: 'Περιοχή',
	mapExplain: 'Κάντε κλικ στον χάρτη για να επιλέξετε την τοποθεσία του καταστήματος',
	mapExplain2: 'Επειτα ζωγραφίστε ένα πολύγωνο για να καθορίσετε την περιοχή παράδοσης',
	notes: 'Σημειώσεις',
	gallery: 'Γκαλερί',
	logo: 'Λογότυπο',
	cover: 'Εξώφυλλο',
	main: 'Κύρια',
	uploadLogo: 'Ανέβασμα Logo εικόνας',
	uploadCover: 'Ανέβασμα Cover εικόνας',
	adminInfo: 'Πληροφορίες Διαχειριστή',
	itemCatName: 'Όνομα Κατηγορίας',
	itemCat: 'Κατηγορία Προϊόντος',
	description: 'Περιγραφή',
	price: 'Τιμή',
	available: 'Διαθέσιμο',
	modifiers: ' Γκρουπ Υλικών',
	modifier: 'Γκρουπ Υλικών',
	modfierName: 'Όνομα Υλικού',
	type: 'Τύπος',
	radioButton: 'Μια',
	checkbox: 'Πολλές Επιλογές',
	required: 'Υποχρεωτικό',
	yes: 'Ναι',
	no: 'Όχι',
	options: 'Επιλογές',
	modifierOptions: 'Υλικά',
	modifierOption: 'Υλικό',
	//   additionalTax: "Επιπλέον Φόρος",
	image: 'Εικόνα',
	passwordError: 'Παρακαλώ συμπληρώστε τον κωδικό',
	emailError: 'Παρακαλώ συμπληρώστε το email',
	haveDel: ' διαγράφτηκαν',
	hasDel: ' διαγράφτηκε',
	selected: 'Επιλεχθηκαν',
	info: 'Πληροφορίες',
	categories: 'Κατηγορίες',
	items: 'Προϊόντα',
	category: 'Κατηγορία',
	item: 'Προϊόν',
	liveOrders: 'Παραγγελίες',
	orderHistory: 'Ιστορικό Παραγγελιών',
	order: 'Παραγγελία',
	orderID: 'ID Παραγγελίας',
	status: 'Κατάσταση',
	date: 'Ημερομηνία',
	myShop: 'Το κατάστημά μου',
	coupons: 'Κουπόνια',
	addCoupon: 'Προσθήκη Κουπονιού',
	editCoupon: 'Επεξεργασία Κουπονιού',
	discount: 'Έκπτωση',
	minOrder: 'Ελάχιστη Παραγγελία',
	deliveryFee: 'Κόστος Παράδοσης',
	selectShop: 'Επιλογή Καταστήματος',
	editData: 'Επεξεργασία Δεδομένων',
	message: {
		success: {
			city: 'Η   πόλη δημιουργήθηκε επιτυχώς',
			cityUpdate: 'Η πόλη ενημερώθηκε επιτυχώς',
			cityDelete: 'Η πόλη διαγράφτηκε επιτυχώς',
			user: 'Ο χρήστης δημιουργήθηκε επιτυχώς',
			userUpdate: 'Ο χρήστης ενημερώθηκε επιτυχώς',
			userDelete: 'Ο χρήστης διαγράφτηκε επιτυχώς',
			shop: 'Το κατάστημα δημιουργήθηκε επιτυχώς',
			shopUpdate: 'Το κατάστημα ενημερώθηκε επιτυχώς',
			shopDelete: 'Το κατάστημα διαγράφτηκε επιτυχώς',
			cuisine: 'Η κουζίνα δημιουργήθηκε επιτυχώς',
			cuisineUpdate: 'Η κουζίνα ενημερώθηκε επιτυχώς',
			cuisineDelete: 'Η κουζίνα διαγράφτηκε επιτυχώς',
		},
		error: {
			general: 'Κάτι πήγε στραβά',
			itemMove: 'Το προϊόν μπορεί να μετακινηθει μόνο στο ιδιο επίπεδο',
		},
	},
};
