import React, { useEffect, useState } from 'react';
import {
	Collapse,
	Flex,
	Tree,
	message,
	Tag,
	Typography,
	Select,
	Switch,
	Divider,
	Button,
} from 'antd';
import Info from '../store/shop/components/Info';
import ItemCategoryForm from '../store/shop/itemCategories/ItemCategoryForm';
import MyModal from '../UI/Modal';
import ItemForm from '../store/shop/items/ItemsForm';
import ModifierForm from '../store/shop/modifiers/ModifierForm';
import ModifierOptionsForm from '../store/shop/modifierOptions/ModifierOptionsForm';
import { useDispatch } from 'react-redux';
import api from '../../utils/http';
import { translations } from '../../utils/translations';
import { isMobile, isDesktop } from 'react-device-detect';
import { ReorderModal } from './ReorderModal';

const { Text } = Typography;

const apiCall = {
	1: '/item-categories',
	2: '/items',
	3: '/modifiers',
	4: '/modifier-options',
};

const TreeCompo = ({
	shopData,
	setShopData,
	data,
	onFinish,
	user,
	open,
	setOpen,
	selectedID,
	depth,
	setDepth,
	addData,
	setAddData,
	coupons,
	onActiveChange,
	getTreeData,
	openedMobileReorderNode,
	closeMobileReorderNode,
	openCategoriesMobileReorder,
}) => {
	const dispatch = useDispatch();
	const { patch, put } = api();

	const [dragAllowed, setDragAllowed] = useState(false);

	const [expandedKeys, setExpandedKeys] = useState([]);
	const [autoExpandParent, setAutoExpandParent] = useState(true);
	const [gData, setGData] = useState([]); // Local state to handle reordering

	const [oldgData, setOldGData] = useState([]);

	const [draggedKeys, setDraggedKeys] = useState([]);

	useEffect(() => {
		setGData(data); // Sync local gData state with data from props
	}, [data]);

	useEffect(() => {
		if (!dragAllowed) {
			return;
		}
		setOldGData(gData);
		setDraggedKeys([]);
	}, [dragAllowed]);

	const onSelect = (selectedKeys, info) => {
		const key = info.node.key;
		const newExpandedKeys = expandedKeys.includes(key)
			? expandedKeys.filter(k => k !== key)
			: [...expandedKeys, key];

		setExpandedKeys(newExpandedKeys);
		setAutoExpandParent(false);
	};

	const onExpand = expandedKeys => {
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	// const onDragEnter = info => {
	// 	// console.log('onDragEnter', info);
	// };

	const findNodeByKey = (nodes, key, parent = null) => {
		for (let i = 0; i < nodes.length; i++) {
			if (nodes[i].key === key) {
				return { node: nodes[i], index: i, parent };
			}
			if (nodes[i].children) {
				const found = findNodeByKey(nodes[i].children, key, nodes[i]);
				if (found) return found;
			}
		}
		return null;
	};

	const onDrop = info => {
		const dropKey = info.node.key;
		const dragKey = info.dragNode.key;
		const dropPositionRelative = info.dropPosition - Number(info.node.pos.split('-').pop());

		// Helper to find node by key, including its index and parent

		const dataClone = [...gData];

		const {
			node: dragNode,
			index: dragIndex,
			parent: dragParent,
		} = findNodeByKey(dataClone, dragKey) || {};
		const {
			node: dropNode,
			index: dropIndex,
			parent: dropParent,
		} = findNodeByKey(dataClone, dropKey) || {};

		// console.log(dragParent, dropParent, dragNode, dropNode);
		console.log(dragParent?.key, dropParent?.key);
		console.log(dragNode?.key, dropNode?.key);
		// Only reorder if nodes have the same parent
		if (dragParent !== dropParent || !dragNode || !dropNode) {
			message.error(translations.message.error.itemMove);
			return;
		}

		// Remove dragNode from its original position
		const siblings = dragParent ? dragParent.children : dataClone;
		siblings.splice(dragIndex, 1);

		// Insert dragNode at the new position within the same level
		const newIndex = dropPositionRelative === -1 ? dropIndex : dropIndex + 1;
		siblings.splice(newIndex, 0, dragNode);

		setDraggedKeys([...draggedKeys, dragParent?.key || 'categories']); // an den exei value tote exei kounisei category
		setGData(dataClone); // Update local gData state
	};

	const onFinishLocal = async () => {
		try {
			const apiMethod = selectedID ? put : patch;
			const actionUrl = `${apiCall[depth]}/${selectedID || ''}`;

			let dataPayload = { language: user.language };
			console.log('prin', depth, addData.options);

			switch (depth) {
				case 2:
					dataPayload = {
						...dataPayload,
						name: addData.name,
						// shop: user?.shop || undefined,
						category: addData.category,
						// price: addData.price * 100,
						available: !!addData.available,
						// files: !addData?.files ? [] : addData.files?.map(file => file.id),
						// files: item.id
						// 	? item.files?.[0]?.id
						// 		? item.files?.map(file => file.id)
						// 		: item?.files
						// 	: item.files?.map(file => file.id) || [],
						modifiers: addData?.modifiers || [],
						// modifiers: addData?.modifiers?.[0]?.id
						// ? addData?.modifiers?.map(modifier => modifier.id)
						// : [],
						description: addData.description || '',
					};
					break;
				case 3:
					dataPayload = {
						...dataPayload,
						name: addData.name,
						// shop: user.shop,
						// language: user.language,
						type: !!addData.type ? 0 : 1,
						required: !!addData.required,
						options: addData.options || [],
						// ?.[0].id
						// 	? addData.options.map(option => option.id)
						// 	: [],
					};
					break;
				case 4:
					dataPayload = {
						...dataPayload,
						name: addData.name,
						// price: item.price * 100 || 0,
						// additionalTax: item.additionalTax * 100 || 0,
						available: addData.available || false,
						// shop: user.shop,
						// language: user.language,
					};
					break;
			}

			console.log('meta', dataPayload.options);

			dataPayload.files = !addData.files ? [] : addData.files?.map(file => file.id);
			// if (addData.files) {
			// 	dataPayload.files = addData.files.map(x => x.id);
			// }
			// if (addData.price || addData.price == 0) {

			if (depth === 2 || depth === 4) {
				const p = Number(`${addData.price}`?.replaceAll(',', '.'));
				if (!isFinite(p)) {
					message.error('Η τιμή πρέπει να είναι νούμερο');
					return;
				}
				dataPayload.price = Math.round(p * 100); // (addData.price || 0) * 100;
			}
			// }
			// {
			// 	...addData,
			// 	...(addData.files && { files: addData.files.map(x => x.id) }),
			// 	...(addData.price && { price: addData.price * 100 }),
			// 	// ...(addData.additionalTax >= 0 && {
			// 	// 	additionalTax: addData.additionalTax * 100,
			// 	// }),

			// };

			// itan me dispatch(
			apiMethod(actionUrl, dataPayload)
				.then(response => {
					console.log('dadada');
					setAddData({});
					setOpen(false);
					getTreeData();
				})
				.catch(err => {
					message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
					console.log(err);
				});
		} catch (err) {
			message.error(err?.response?.data?.message || 'Κάποιο πρόβλημα προέκυψε');
			console.log(err);
		}
	};

	const onSaveDrag = async () => {
		try {
			// console.log(gData);
			// console.log('draggged', draggedKeys);
			const ma = new Map(draggedKeys.map(item => [item, true]));
			const arr = Array.from(ma.keys());

			const toSendData = { items: [], modifiers: [], modifierOptions: [] };

			const shouldSendCategories = arr.find(it => it === 'categories');

			arr.forEach(dk => {
				const split = dk.split('-');

				if (dk.startsWith('category')) {
					console.log('dkkkk', dk);

					const cat = gData.find(cat => cat.key === `category-${split[1]}`);
					// const item = cat.children.find(item => item.key === `item-${split[1]}-${split[2]}`);

					// const modifier = item.children.find(
					// 	mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
					// );
					// if (!modifier.children?.length) {
					// 	return;
					// }
					toSendData.items.push({
						items: cat.children.map(c => {
							const split = c.key.split('-');
							return Number(split[split.length - 1]);
						}),
						categoryId: Number(split[split.length - 1]),
					});
				} else if (dk.startsWith('item')) {
					const cat = gData.find(cat => cat.key === `category-${split[1]}`);
					const item = cat.children.find(
						item => item.key === `item-${split[1]}-${split[2]}`,
					);

					// const modifier = item.children.find(
					// 	mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
					// );
					// if (!modifier.children?.length) {
					// 	return;
					// }
					toSendData.modifiers.push({
						modifiers: item.children.map(mod => {
							const split = mod.key.split('-');
							return Number(split[split.length - 1]);
						}),
						itemId: Number(split[split.length - 1]),
					});
				} else if (dk.startsWith('modifier')) {
					// EXO PEIRAKSEI OPTION DILADI
					const cat = gData.find(cat => cat.key === `category-${split[1]}`);
					const item = cat.children.find(
						item => item.key === `item-${split[1]}-${split[2]}`,
					);

					const modifier = item.children.find(
						mod => mod.key === `modifier-${split[1]}-${split[2]}-${split[3]}`,
					);
					if (!modifier.children?.length) {
						return;
					}
					toSendData.modifierOptions.push({
						options: modifier.children.map(mo => {
							const split = mo.key.split('-');
							return Number(split[split.length - 1]);
						}),
						modifierId: Number(split[split.length - 1]),
					});
				}
			});

			console.log('toSendData', toSendData);

			const promises = [];

			if (shouldSendCategories) {
				const itemCategories = gData.map(it => {
					const split = it.key.split('-');
					return Number(split[split.length - 1]);
				});
				// toSendData.items.push({
				// 	items: cat.children.map(c => {
				// 		const split = c.key.split('-');
				// 		return Number(split[split.length - 1]);
				// 	}),
				// 	categoryId: Number(split[split.length - 1]),
				// });
				promises.push(
					async () => await patch('/item-categories/reorder', { itemCategories }),
				);
			}

			for (let i = 0; i < toSendData.items.length; i++) {
				promises.push(async () => await patch('/items/reorder', toSendData.items[i]));
			}
			for (let i = 0; i < toSendData.modifiers.length; i++) {
				promises.push(
					async () => await patch('/modifiers/reorder', toSendData.modifiers[i]),
				);
			}
			for (let i = 0; i < toSendData.modifierOptions.length; i++) {
				promises.push(
					async () =>
						await patch('/modifier-options/reorder', toSendData.modifierOptions[i]),
				);
			}

			// const itemCategories = gData.map(it => {
			// 	const split = it.key.split('-');
			// 	return Number(split[split.length - 1]);
			// });
			// console.log(itemCategories);
			// const action = await patch('/item-categories/order', { itemCategories });

			// console.log('gamo');
			setDragAllowed(false);
			await Promise.all(promises.map(p => p()));
			message.success('Επιτυχία!');
			// const apiCall = selectedID ? apiCallEdit[depth] : apiCallADD[depth];
			// const dataPayload = {
			// 	...addData,
			// 	...(addData.files && { files: addData.files.map(x => x.id) }),
			// 	...(addData.price && { price: addData.price * 100 }),
			// 	...(addData.additionalTax >= 0 && {
			// 		additionalTax: addData.additionalTax * 100,
			// 	}),
			// 	language: user.language,
			// };

			// dispatch(apiMethod(patch, dataPayload))
			// 	.then(response => {
			// 		setAddData({});
			// 		setOpen(false);
			// 	})
			// 	.catch(error => {
			// 		message.error(error.response.data.message);
			// 	});
		} catch (err) {
			message.success('Κάποιο πρόβλημα προέκυψε');

			console.log('err onSaveDrag', err);
		}
	};

	const modalComponent = {
		1: <ItemCategoryForm item={addData} setItem={setAddData} onFinish={onFinishLocal} />,
		2: <ItemForm item={addData} setItem={setAddData} onFinish={onFinishLocal} user={user} />,
		3: (
			<ModifierForm
				item={addData}
				setItem={setAddData}
				onFinish={onFinishLocal}
				user={user}
				edit={!!selectedID}
			/>
		),
		4: <ModifierOptionsForm item={addData} setItem={setAddData} onFinish={onFinishLocal} />,
	};

	// console.log('Mobile:', isMobile);
	// // console.log('Tablet:', isTablet);
	// console.log('Desktop:', isDesktop);
	return (
		<Flex vertical>
			<Collapse ghost>
				<Collapse.Panel header={translations.myShop} key='1'>
					<Info
						setShopData={setShopData}
						shopData={shopData}
						user={user}
						onFinish={onFinish}
					/>
				</Collapse.Panel>
				<Collapse.Panel header={translations.coupons} key='2'>
					{coupons.map(coupon => (
						<Flex vertical key={coupon.id}>
							<Flex justify='space-between'>
								<Tag color={coupon.active ? 'green' : 'red'}>{coupon.code}</Tag>
								<Text>
									{`${translations.discount}: ${coupon.discount / 100}${coupon.type === 0 ? '€' : '%'}`}
								</Text>
								<Text>
									{translations.minOrder}: {coupon.minimumOrder / 100}€
								</Text>
								<Switch
									checked={coupon.active}
									onChange={() => onActiveChange(coupon.id)}
								/>
							</Flex>
							<Divider />
						</Flex>
					))}
				</Collapse.Panel>
				<Collapse.Panel header={translations.editData} key='3'>
					<Flex vertical justify='center' align='center' style={{ width: '100%' }}>
						<Text>{translations.addNew}</Text>
						<Select
							value={depth}
							style={{ marginBottom: 12, width: 130 }}
							onChange={value => {
								setDepth(value);
								setOpen(true);
							}}
							options={[
								{ value: 1, label: <Text>{translations.itemCat}</Text> },
								{ value: 2, label: <Text>{translations.item}</Text> },
								{ value: 3, label: <Text>{translations.modifier}</Text> },
								{ value: 4, label: <Text>{translations.modifierOption}</Text> },
							]}
						/>
					</Flex>

					{!isDesktop ? (
						<>
							<Button
								onClick={
									dragAllowed
										? onSaveDrag
										: () => {
												setDragAllowed(true);
											}
								}
							>
								{dragAllowed ? 'Αποθήκευση σειράς' : 'Αλλαγή σειράς'}
							</Button>
							{dragAllowed && (
								<Button
									onClick={() => {
										setDragAllowed(false);
										setGData(oldgData);
									}}
								>
									Ακύρωση σειράς
								</Button>
							)}
						</>
					) : (
						<Button onClick={openCategoriesMobileReorder}>
							Αλλαγή σειράς κατηγοριών
						</Button>
					)}

					<Tree
						treeData={gData} // Use the local gData state for rendering
						onSelect={onSelect}
						onExpand={onExpand}
						expandedKeys={expandedKeys}
						autoExpandParent={autoExpandParent}
						// onDragEnter={onDragEnter}
						onDrop={onDrop}
						draggable={dragAllowed}
						showLine
					/>
				</Collapse.Panel>
			</Collapse>
			{openedMobileReorderNode && (
				<ReorderModal
					openedMobileReorderNode={openedMobileReorderNode}
					close={closeMobileReorderNode}
					patch={patch}
					getTreeData={getTreeData}
				/>
			)}
			<MyModal open={open} setOpen={setOpen} setData={setDepth}>
				{modalComponent[depth]}
			</MyModal>
		</Flex>
	);
};

export default TreeCompo;
